@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Flamenco&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html,body {
  width: 100%; height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  display: flex;
	justify-content: center;
	align-items: center;
  --cursorColor: #ffffff;


}
#root {
  --accent: #3ea2e7;
  width: 100%; height:100%;
  display: flex;
	justify-content: center;
	align-items: center;
  background-color: #eee;
}
p::selection {
  color: #fff;
  background-color: var(--accent);
}
input::selection {
  color: var(--accent);
  background-color: #fff;
}

.gradiantAnimation{
  background: linear-gradient(-65deg,  #f50000, #c9d523, #23d5ab, #0087f5, #f32574);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}