.chatPage{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  >.window{
    flex-grow: 1;
  }
}

.widgetImg{
  width: 5px;
  height: 5px;
  background-color: black;
}

.my-cursor{
  width: 5px;
  height: 5px;
  background: #ff0000;
}
