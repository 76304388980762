.checkboxMain{
    display: flex;
    width: max-content;
    margin-left: 10%;
    align-items: center;
    justify-content: center;

    >.checkbox {
            --border-default: #bbbbc1;
            --border-hover: #9898a3;
            --active: #f54500;
            --active-tick: #ffffff;
            display: block;
            width: 18px;
            height: 18px;
            cursor: pointer;
            position: relative;
            -webkit-tap-highlight-color: transparent;
            svg {
                display: block;
                position: absolute;
            }
            input {
                display: block;
                outline: none;
                border: none;
                padding: 0;
                margin: 0;
                -webkit-appearance: none;
                width: 18px;
                height: 18px;
                border-radius: 36% / 36%;
                box-shadow: inset 0 0 0 1.5px var(--border, var(--border-default));
                background: var(--background, transparent);
                transition: background .25s linear, box-shadow .25s linear;
                & + svg {
                    width: 21px;
                    height: 18px;
                    left: 0;
                    top: 0;
                    color: var(--active);
                    .tick {
                        stroke-dasharray: 20;
                        stroke-dashoffset: var(--stroke-dashoffset, 20);
                        transition: stroke-dashoffset .2s;
                        &.mask { 
                            stroke: var(--active-tick);
                        }
                    }
                    & + svg {
                        width: 11px;
                        height: 11px;
                        fill: none;
                        stroke: var(--active);
                        stroke-width: 1.25;
                        stroke-linecap: round;
                        top: -6px;
                        right: -10px;
                        stroke-dasharray: 4.5px;
                        stroke-dashoffset: 13.5px;
                        pointer-events: none;
                        animation: var(--animation, none) .2s ease .175s;
                    }
                }
                &:checked {
                    --background: var(--active);
                    --border: var(--active);
                    & + svg {
                        --stroke-dashoffset: 0;
                        & + svg {
                            --animation: check;
                        }
                    }
                }
            }
            &:hover {
                input {
                    &:not(:checked) {
                        --border: var(--border-hover);
                    }
                }
            }
    }

    >span{
        margin-left: 8px;
        font-size: 14px;
        color: white;
    }
}

@keyframes check {
    100% {
        stroke-dashoffset: 4.5px;
    }
}