.homePage{
  position: absolute;
  width: 100%; height: max-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #111;
  top: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;

  >.roomOpenLoading{
    position: absolute;
    width: 100%; height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 999;
  }

  >.body{
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    >.createRoom{
      width: 100%; height: 90px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      flex-direction: row;
      padding-left: min(100px, 5%);
      user-select: none;

      >.createBtn{
        width: 120px; height: 48px;
        background: linear-gradient(-45deg,  #f35f25, #ff4800);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 4px;
        box-shadow: none;
        border: none;
        transition: 0.15s;
        margin-right: 40px;
        font-size: 1rem;
        user-select: none;

        >p{
          margin-right: 8px;
        }

        >.addIcon{
          width: 18px; height: 18px;
          fill: white;
          transform: rotate(45deg);
        }

        &:hover{
          box-shadow: 0px 0px 14px 0px #f54500e8;
          transition: 0.15s;
          cursor: pointer;
        }

        &:active{
          background-color: #f15f25;
          transition: 0.15s;
        }

        >.addIconSpin{
          animation: spin 2s cubic-bezier(0.35, 0.28, 0.45, 0.88);
        }

        @keyframes spin{
          from{
            transform: rotate(45deg);
          }
          to{
            transform: rotate(1755deg);
          }
        }
      }

      >.joinInput{
        width: 350px; height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        >.inputField{
          width: calc(100% - 2px); height: calc(100% - 2px);
          color: rgb(230, 230, 230);
          border-radius: 4px;
          box-shadow: 0 0 0 1px white;
          background-color: #222222;
          border: none;
          transition: 0.15s;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: space-between;
          background-image: url(https://img.icons8.com/ios-glyphs/30/ffffff/keyboard.png);
          background-repeat: no-repeat;
          background-position: left;
          background-position-x: 10px;
          padding-left: 50px;
          font-size: 1rem;
          letter-spacing: .00625em;
          user-select: none;
          outline: none !important;

          &:hover{
            box-shadow: 0 0 0 1px #e6571f;
            border: none;
            background-image: url(https://img.icons8.com/ios-glyphs/30/999999/keyboard.png);
            transition: 0.15s;
            cursor: pointer;
          }

          &:focus-visible{
            border: none;
            box-shadow: 0 0 0 1px #7da88800;
            // width: calc(100% - 2px); height: calc(100% - 2px);
            background-image: url(https://img.icons8.com/ios-glyphs/30/f54500/keyboard.png);
            cursor: pointer;
          }
        }
      }

      >.joinBtn{
        width: 60px; height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #e6571f;
        border: none;
        user-select: none;
        margin-left: 8px;
        border-radius: 4px;

        &:hover{
          transition: 0.15s;
          cursor: pointer;
          background-color: #f5450015;
        }
      }
    }

    >.ownRoom{
      width: 100%; height: max-content;
      margin-top: 40px;

      >.ownRoomTitle{
        width: 100%; height: 48px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        box-sizing: border-box;
        padding-left: min(100px, 5%);
        padding-right: min(100px, 5%);
        color: rgb(255, 255, 255);
        font-size: large;
        flex-direction: column;

        >.border{
          width: 100%; 
          border-top: 1px solid rgb(168, 168, 168);
        }
      }

      >.ownRoomBody{
        width: 100%; height: max-content;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        justify-content: start;
        box-sizing: border-box;
        gap: 40px;
        margin-bottom: 30px;
        padding: 40px min(100px, 5%) 0 min(100px, 5%);
        padding-top: 10px;
      }
    }
  }
}

.blur{
  transition: 1s;
  filter: blur(2px);
}



.MuiPaper-root {
  background-color: #222 !important;
}

@media (min-width: 600px){
  .MuiToolbar-root {
    min-height: 48px !important;
  }
}

@media only screen and (max-width: 600px) {
  .homePage{
    >.body{
      >.createRoom{
        height: max-content;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: column;
        row-gap: 20px;

        >.joinInput{
          width: min(300px, 80vw);
        }
      }

      >.ownRoom{
        margin-top: 20px;
      }
    }
  }
}

@media (min-width: 1536px){
  .MuiContainer-root {
      max-width: none !important;
  }
}
