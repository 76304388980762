
.multiCursor{
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    >.line{
        position: relative;
        border: 2px solid #89898936;
        height: 30px;
        border-radius: 8px;
        margin-left: 25px;
        background: #68686840;

        >.widget{
            position: absolute;
            top: -20px;
            width: 50px;
            height: 40px;
            display: flex;
            justify-content: center;
            font-size: 13px;
            font-weight: 500;
            color: white;
            user-select: none;
            clip-path: polygon(0 100%, 0 0, 60% 0%, 100% 0, 100% 46%, 12% 47%, 12% 100%);
        }

        >.widget1{
            background: #d13553;
            right: -40px;
        }

        >.widget2{
            background: #39cfcf;
            animation: widget2 8s cubic-bezier(0.32, 0.15, 0.89, 1.08) infinite;
            left: auto !important;
            right: -40px !important;
        }

        >.widget3{
            background: #3dc961;
            animation: widget3 9s cubic-bezier(0.32, 0.15, 0.89, 1.08) infinite;
        }

        >.widget4{
            background: pink;
            visibility: hidden;
        }

        >.widget5{
            background: #39cfcf;
            visibility: hidden;
            right: -40px;
            animation: widget5 8s cubic-bezier(0.32, 0.15, 0.89, 1.08) infinite;
        }

        @keyframes  widget2{
            0%  {visibility:  hidden}
            2%  {visibility:  hidden}
            3%  {visibility: visible}
            22% {visibility: visible}
            23% {visibility:  hidden}
            57% {visibility:  hidden}
            58% {visibility: visible}
            79% {visibility: visible}
            80% {visibility:  hidden}
            100%{visibility:  hidden}
        }

        @keyframes  widget3{
            0%  {left: auto; right: 40px }
            34% {left: auto; right: 40px }
            35% {left: 10px; right: auto }
            60% {left: auto; right: -40px}
            85% {left: auto; right: -40px}
            90% {left: auto; right: -40px}
            95% {left: auto; right: 40px }
            100%{left: auto; right: 40px }
        }

        @keyframes  widget5{
            0%  {visibility: hidden }
            22% {visibility: hidden }
            23% {visibility: visible}
            57% {visibility: visible}
            58% {visibility: hidden }
            79% {visibility: hidden }
            80% {visibility: visible}
            99% {visibility: hidden }
            100%{visibility: hidden }
        }
    }

    >.line1{
        width:50px;
        animation: line1 5s cubic-bezier(0.32, 0.15, 0.89, 1.08) 4s infinite;
    }

    >.line2{
        width: 150px;
        animation: line2 8s cubic-bezier(0.32, 0.15, 0.89, 1.08) infinite;
    }

    >.line3{
        width: 200px;
        animation: line3 9s cubic-bezier(0.32, 0.15, 0.89, 1.08) infinite;
    }

    >.line4{
        width: 180px;
    }

    >.line5{
        width: 40px;
        animation: line5 8s cubic-bezier(0.32, 0.15, 0.89, 1.08) infinite;
    }

    @keyframes line1 {
        0%  {width: 60px }
        30% {width: 250px}
        50% {width: 250px}
        70% {width: 60px }
        100%{width: 60px }
    }

    @keyframes line2 {
        0%  {width: 150px}
        5%  {width: 150px}
        20% {width: 70px }
        60% {width: 70px }
        78% {width: 150px}
        100%{width: 150px}
    }

    @keyframes line3 {
        0%  {width: 230px}
        25% {width: 100px}
        35% {width: 100px}
        45% {width: 20px }
        60% {width: 20px }
        85% {width: 230px}
        100%{width: 230px}
    }

    @keyframes line5 {
        0%  {width: 40px }
        25% {width: 40px }
        55% {width: 240px}
        82% {width: 240px}
        98% {width: 40px }
    }
}